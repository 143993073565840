import "./Datenschutz.css"
import {Link, Typography} from "@mui/material";
import * as React from "react";

export default function Datenschutz() {
    const vorstandEmail = '1.vorstand@musikverein-wissgoldingen.de';
    const vorstandEmailLink = `mailto:${vorstandEmail}`;
    const vorstandMobile = "0171 / 7967421"
    const vorstandMobileLink = `tel:${vorstandMobile}`
    const vorstandTel = "07332 / 309115"
    const vorstandTelLink = `tel:${vorstandTel}`

    return (
        <div className="datenschutz_flex_div">
            <div className="datenschutz">
                <Typography variant="h2">
                    Datenschutz
                </Typography>

                <Typography variant="h4" gutterBottom>
                    Verantwortliche Stelle
                </Typography>
                <Typography>
                    Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
                    EU-Datenschutzgrundverordnung (DSGVO), ist:
                </Typography>
                <Typography>
                    Michael Wiget, 1. Vorsitzender
                </Typography>
                <Typography>
                    Friedhofstraße 32, 73111 Lauterstein-Nenningen
                </Typography>
                <Typography>
                    <strong>Telefon: </strong>
                    <Link href={vorstandTelLink} color="inherit">
                        {vorstandTel}
                    </Link>
                </Typography>
                <Typography>
                    <strong>Mobil: </strong>
                    <Link href={vorstandMobileLink} color="inherit">
                        {vorstandMobile}
                    </Link>
                </Typography>
                <Typography>
                    <strong>E-Mail: </strong>
                    <Link href={vorstandEmailLink} color="inherit">
                        {vorstandEmail}
                    </Link>
                </Typography>

                <Typography variant="h4" gutterBottom>
                    Allgemeines
                </Typography>
                <Typography paragraph>
                    Wir als Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
                    Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften,
                    sowie dieser Datenschutzerklärung.
                </Typography>
                <Typography paragraph>
                    Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
                    unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben
                    werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                    ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                </Typography>
                <Typography paragraph>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                    nicht möglich.
                </Typography>
                <Typography paragraph>
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
                    Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
                    ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
                    im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                </Typography>

                <Typography variant="h4" gutterBottom>
                    Ihre Betroffenenrechte
                </Typography>
                <Typography component="div">
                    <ul>
                        <li>Auskunft über ihre bei uns gespeicherten Daten und deren Verarbeitung</li>
                        <li>Berichtigung personenbezogener Daten</li>
                        <li>Löschung ihrer bei uns gespeicherten Daten</li>
                        <li>Einschränkung der Datenverarbeitung, sofern wir ihre Daten aufgrund gesetzlicher Pflichten
                            noch nicht löschen dürfen
                        </li>
                        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns</li>
                    </ul>
                </Typography>
                <Typography paragraph>
                    Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden.
                    Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit
                    oder der mutmaßlichen Verletzung.
                </Typography>

                <Typography variant="h4" gutterBottom>
                    Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte
                </Typography>
                <Typography paragraph>
                    Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten
                    Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken
                    findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
                </Typography>
                <Typography component="div">
                    <ul>
                        <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben</li>
                        <li>die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist</li>
                        <li>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist</li>
                        <li>die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur
                            Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe
                            Ihrer Daten haben
                        </li>
                    </ul>
                </Typography>

                <Typography variant="h4" gutterBottom>
                    Erfassung allgemeiner Informationen beim Besuch unserer Website
                </Typography>
                <Typography paragraph>
                    Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen
                    allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des
                    Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und
                    ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf
                    Ihre Person zulassen.
                </Typography>
                <Typography paragraph>
                    Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten
                    korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu
                    folgenden Zwecken verarbeitet:
                </Typography>
                <Typography component="div">
                    <ul>
                        <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website</li>
                        <li>Sicherstellung einer reibungslosen Nutzung unserer Website</li>
                        <li>Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken
                        </li>
                    </ul>
                </Typography>
                <Typography paragraph>
                    Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus den
                    vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre
                    Person zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf.
                    Auftragsverarbeiter. Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet,
                    um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.
                </Typography>

                <Typography variant="h4" gutterBottom>
                    Cookies
                </Typography>
                <Typography paragraph>
                    Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine
                    Textdateien, die von einem Websiteserver auf Ihre Festplatte übertragen werden. Hierdurch erhalten
                    wir automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem und Ihre
                    Verbindung zum Internet.
                </Typography>
                <Typography paragraph>
                    Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu
                    übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation
                    erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.
                </Typography>
                <Typography paragraph>
                    In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre
                    Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.
                    Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Internet-Browser
                    sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die
                    Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden
                    Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern
                    können. Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht
                    funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.
                </Typography>

                <Typography variant="h4" gutterBottom>
                    Änderung unserer Datenschutzbestimmungen
                </Typography>
                <Typography paragraph>
                    Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen
                    rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
                    Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch
                    gilt dann die neue Datenschutzerklärung.
                </Typography>

                <Typography variant="h4" gutterBottom>
                    Fragen an die für den Datenschutz verantwortliche Stelle
                </Typography>
                <Typography paragraph>
                    Wenn Sie Fragen zum Datenschutz haben, wenden Sie sich bitte direkt an die für den Datenschutz
                    verantwortliche Person, Herrn Michael Wiget.
                </Typography>
            </div>
        </div>
    )
}