import "./MainPage.css";
import {ButtonBase, Grid, Link, Typography} from "@mui/material";

import img_mv_gesamt from '../images/main_page_bilder/mvw_2019_cropped.jpg';
import img_juka_gesamt from "../images/juka_bilder/0_gruppenbild_vor_altweibermühle.jpg";
import img_mv_kirchenkonzert from "../images/main_page_bilder/kirchenkonzert_mvw.jpeg";
import img_posaune_basteln from "../images/main_page_bilder/posaune_basteln.jpeg";
import img_fasching_2025_flyer from "../images/aktuelles/Flyer_Druck.jpeg";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/system";

function MainPage() {
    const navigate = useNavigate();
    const theme = useTheme()

    const handlePageChange = (page) => {
        switch (page) {
            case "Jugendkapelle":
                navigate("/jugend", {state: {"scrollToComponent": null}})
                break
            case "Ausbildung":
                navigate("/jugend", {state: {"scrollToComponent": "ausbildung"}})
                break
            case "Musikverein":
                navigate("/musikverein")
                break
            default:
                break
        }
    };

    return (
        <div className="main_page_outer">
            <img
                className="mvw_gesamt_img"
                src={img_mv_gesamt}
                alt="Musikverein Gruppenbild"
                width=""
            />
            <div className="main_page_inner">
                <Grid container
                      spacing={3}
                      className="overview">
                    <Grid item xs={12} md={6} className="aktuelles_image">
                        <img
                            className="overview_image"
                            src={img_fasching_2025_flyer}
                            alt="Musikerfasching 2025"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4">
                            <strong>Musikerfasching 2025</strong>
                        </Typography>

                        <Typography variant="h5" gutterBottom>
                            Jaja bald ist’s soweit, bald ist Fasnetszeit
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Der Musikverein Wißgoldingen lädt zum Musikerfasching 2025 in die Kaiserberghalle ein.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Wann?</strong> Am 15. Feb. 2025
                            <br/>
                            <strong>Wo?</strong> In der Kaiserberghalle in Wißgoldingen
                            <br/>
                            Beginn ist um 19:30 Uhr
                            <br/>
                            Einlass um 18:30 Uhr
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Sichert euch jetzt online Tickets unter:{' '}
                            <Link href="https://eventfrog.de/TicketsMusikerfasching25" target="_blank" rel="noopener">
                                https://eventfrog.de/TicketsMusikerfasching25
                            </Link>
                            <br/>
                            Oder vor Ort am 23. Jan und 06. Feb. von 18:30–19:30 Uhr im Foyer der Grundschule
                            Wißgoldingen.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Es werden folgende Kategorien im VVK angeboten:
                            <br/>
                            <strong>Sitzplatzkarten</strong>: 13€
                            <br/>
                            <strong>Stehplatzkarten</strong>: 11€
                            <br/>
                            An der Abendkasse beträgt der Eintrittspreis 14€. Kinder unter 14 Jahren ohne festen
                            Sitzplatz haben freien Eintritt.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Es erwartet euch ein bunter Abend mit tollen Showeinlagen, Büttenreden, Unterhaltungsmusik
                            und Tanzmöglichkeit. Lasst euch vom Auftritt des Wißgoldinger Prinzenpaares überraschen.
                            Durch das Programm führt die Showband „More Fun“.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Wir freuen uns auf euer Kommen! <strong>Dreifaches Stuifa Hau</strong>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container
                      spacing={1}
                      className="overview">
                    <Grid item xs={12} md={4} order={{xs: 1, md: 1}}>
                        <ButtonBase
                            onClick={() => handlePageChange("Ausbildung")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <Typography variant="h4">
                                <strong>Ausbildung</strong>
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 2, md: 4}} className="overview_text">
                        <ButtonBase
                            onClick={() => handlePageChange("Ausbildung")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <Typography align="left">
                                Bevor unser Nachwuchs in der Jugendkapelle spielen kann, muss natürlich zuerst ein
                                Instrument
                                erlernt werden. Angefangen bei der Blockflöte für die Jüngeren, bis hin zu einem Holz-,
                                Blechblasinstrument oder Schlagzeug wird bei uns im Verein ausgebildet.
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 3, md: 7}}>
                        <ButtonBase
                            onClick={() => handlePageChange("Ausbildung")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <img
                                className="grid_image"
                                src={img_posaune_basteln}
                                alt="Posaune basteln"
                            />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 4, md: 2}}>
                        <ButtonBase
                            onClick={() => handlePageChange("Jugendkapelle")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <Typography variant="h4">
                                <strong>Jugendkapelle</strong>
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 5, md: 5}} className="overview_text">
                        <ButtonBase
                            onClick={() => handlePageChange("Jugendkapelle")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <Typography align="left">
                                In unserer Jugendkapelle, dirigiert von Florian Häfele, lernen junge Musiker das
                                gemeinsame
                                Musizieren
                                in
                                Vorbereitung auf die aktive Kapelle. Neben der Probenarbeit und mehreren Auftritten
                                jedes
                                Jahr
                                steht
                                die Kameradschaft sehr weit vorne.
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 6, md: 8}}>
                        <ButtonBase
                            onClick={() => handlePageChange("Jugendkapelle")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <img
                                className="grid_image"
                                src={img_juka_gesamt}
                                alt="Jugendkapelle"
                            />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 7, md: 3}}>
                        <ButtonBase
                            onClick={() => handlePageChange("Musikverein")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <Typography variant="h4">
                                <strong>Aktive Kapelle</strong>
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 8, md: 6}} className="overview_text">
                        <ButtonBase
                            onClick={() => handlePageChange("Musikverein")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <Typography align="left">
                                In der aktiven Kapelle spielen wir neben zwei Konzerten mit anspruchsvoller
                                Blasmusik-Literatur
                                jedes Jahr diverse Auftritte. Dazu gehören unter anderem verschiedene Gartenfeste,
                                kirchliche
                                Auftritte und Besuche von befreundeten Vereinen.
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 9, md: 9}}>
                        <ButtonBase
                            onClick={() => handlePageChange("Musikverein")}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.hover.main
                                }
                            }}
                        >
                            <img
                                className="grid_image"
                                src={img_mv_kirchenkonzert}
                                alt="Musikverein Kirchenkonzert"
                            />
                        </ButtonBase>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default MainPage