import * as React from 'react';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import mvLogo from "./images/mvw_logo_transparent.png";
import "./ToolBar.css";
import {useNavigate} from "react-router-dom";
import {Menu, MenuItem} from "@mui/material";

const pages = ["Musikverein", "Galerie", "Kontakt"];

function ResponsiveAppBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    let currentlyHovering = false;

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleHover() {
        currentlyHovering = true;
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleCloseHover() {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 50);
    }

    const handlePageChange = (page) => {
        switch (page) {
            case "Jugend":
                navigate("/jugend", {state: {"scrollToComponent": null}});
                break
            case "Musikverein":
                navigate("/musikverein")
                break
            case "Galerie":
                navigate("/galerie");
                break;
            case "Kontakt":
                navigate("/kontakt");
                break;
            case "Home":
                navigate("/");
                break;
            default:
                break;
        }
    };

    const goToJugendAndScrollTo = (component) => {
        handleClose();
        navigate("/jugend", {state: {"scrollToComponent": component}});
    };

    return (
        <AppBar color="background" position="static">
            <Container className="toolbar">
                <Toolbar
                    disableGutters
                >
                    <IconButton
                        onClick={() => handlePageChange("Home")}
                    >
                        <img className="mvwLogo" src={mvLogo} alt={"MVW Logo"}/>
                    </IconButton>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex'}}}>
                        <Button
                            key="Jugend"
                            onClick={() => handlePageChange("Jugend")}
                            onMouseOver={handleClick}
                            onMouseLeave={handleCloseHover}
                            color="secondary"
                        >
                            Jugend
                        </Button>
                        <Menu
                            id="jugend_menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{
                                onMouseEnter: handleHover,
                                onMouseLeave: handleCloseHover,
                                style: {pointerEvents: "auto"}
                            }}
                            anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                            sx={{pointerEvents: "none"}}
                        >
                            <MenuItem onClick={() => goToJugendAndScrollTo()}>
                                Jugendkapelle
                            </MenuItem>
                            <MenuItem onClick={() => goToJugendAndScrollTo("ausbildung")}>
                                Ausbildung
                            </MenuItem>
                            <MenuItem onClick={() => goToJugendAndScrollTo("blockfloete")}>
                                Blockflötenunterricht
                            </MenuItem>
                        </Menu>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                color="secondary"
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <IconButton
                            variant="link"
                            color="primary"
                            className="SocialButton"
                            href="https://www.instagram.com/mv_harmonie_wissgoldingen/"
                        >
                            <InstagramIcon fontSize="large"/>
                        </IconButton>
                        <IconButton
                            variant="link"
                            color="primary"
                            className="SocialButton"
                            href="https://www.facebook.com/MVWissgoldingen/?locale=de_DE"
                        >
                            <FacebookIcon fontSize="large"/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
