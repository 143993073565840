import './Footer.css'
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

function Footer() {
    const navigate = useNavigate();

    const emailAddress = '1.vorstand@musikverein-wissgoldingen.de';
    const mailtoLink = `mailto:${emailAddress}`;

    return (
        <div className="footer_flex_div">
            <div className="footer">
                <div className="column">
                    <Button
                        onClick={() => navigate("/kontakt")}
                        color="background"
                        className="site_link"
                    >
                        Kontakt
                    </Button>
                    <Typography>
                        Michael Wiget
                    </Typography>
                    <Typography>
                        0171 / 7967421
                    </Typography>
                    <a href={mailtoLink} className="mail_link">
                        {emailAddress}
                    </a>
                </div>
                <div className="column">
                    <div className="social_button_div">
                        <IconButton
                            variant="link"
                            color="background"
                            className="SocialButton"
                            href="https://www.instagram.com/mv_harmonie_wissgoldingen"
                        >
                            <InstagramIcon fontSize="large"/>
                        </IconButton>
                        <IconButton
                            variant="link"
                            color="background"
                            className="SocialButton"
                            href="https://www.facebook.com/MVWissgoldingen/?locale=de_DE"
                        >
                            <FacebookIcon fontSize="large"/>
                        </IconButton>
                    </div>
                    <Button
                        onClick={() => navigate("/datenschutz")}
                        color="background"
                        className="site_link"
                    >
                        Datenschutz
                    </Button>
                    <Button
                        onClick={() => navigate("/impressum")}
                        color="background"
                        className="site_link"
                    >
                        Impressum
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Footer;