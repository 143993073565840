import "./Musikverein.css"
import {Grid, Link, Typography} from "@mui/material";
import img_fastnetsumzug_2024 from "../images/aktive_bilder/Fasnetsumzug_2024.jpg"
import img_maibaumfest_2024 from "../images/aktive_bilder/Maibaumfest_2024.JPG"
import img_pfingstfest_2024 from "../images/aktive_bilder/Pfingstfest_2024.JPG"
import Box from "@mui/material/Box";
import * as React from "react";

export default function Musikverein() {
    const vorstandEmail = '1.vorstand@musikverein-wissgoldingen.de';
    const vorstandEmailLink = `mailto:${vorstandEmail}`;
    const vorstandMobile = "0171 / 7967421"
    const vorstandMobileLink = `tel:${vorstandMobile}`

    return (
        <div className="musikverein_flex_div">
            <div className="musikverein">
                <Typography variant="h2" color="primary">
                    <strong>Aktive Kapelle</strong>
                </Typography>
                <Typography paragraph>
                    In der aktiven Kapelle des Musikvereins sind wir derzeit 67 Musikerinnen und Musiker. Wir treffen
                    uns jeden Donnerstag um 20 Uhr zur Probe im Pavillon bei der Grundschule Wissgoldingen. Dort
                    bereiten wir uns auf unsere zahlreichen Auftritte im Laufe des Jahres vor. Neben 2 Konzerten, bei
                    denen wir anspruchsvolle Blasmusikliteratur spielen und die viel Probenarbeit erfordern, spielen wir
                    diverse Unterhaltungsmusik bei verschiedenen Festen und Veranstaltungen in der Umgebung sowie
                    kirchliche Auftritte. Unsere Literatur umfasst neben dem ständig wechselnden Konzertprogramm eine
                    große Auswahl an böhmisch-mährischer Blasmusik sowie modernere Pop- und Rockstücke.
                </Typography>
                <Typography paragraph>
                    Neben der Musik
                    kommt natürlich auch die Kameradschaft nicht zu kurz! Unser Jahresablauf ist gefüllt mit der
                    Organisation von Veranstaltungen, gemeinsamen Ausflügen und vielem mehr.
                </Typography>
                <Typography>
                    Falls Sie nun Lust bekommen haben, bei uns mitzuspielen, kontaktieren Sie uns gerne!
                </Typography>
                <Typography>
                    <strong>Mobil: </strong>
                    <Link href={vorstandMobileLink} color="inherit">
                        {vorstandMobile}
                    </Link>
                </Typography>
                <Typography>
                    <strong>E-Mail: </strong>
                    <Link href={vorstandEmailLink} color="inherit">
                        {vorstandEmail}
                    </Link>
                </Typography>
                <Box p={1}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <img
                            className="grid_image"
                            src={img_maibaumfest_2024}
                            alt="Maibaumfest 2024"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img
                            className="grid_image"
                            src={img_pfingstfest_2024}
                            alt="Pfingstfest 2024"
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <img
                            className="grid_image"
                            src={img_fastnetsumzug_2024}
                            alt="Fastnetsumzug 2024"
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}