import "./Impressum.css";
import {Link, Typography} from "@mui/material";
import * as React from "react";

function Impressum() {
    const vorstandEmail = '1.vorstand@musikverein-wissgoldingen.de';
    const vorstandEmailLink = `mailto:${vorstandEmail}`;
    const vorstandMobile = "0171 / 7967421"
    const vorstandMobileLink = `tel:${vorstandMobile}`
    const vorstandTel = "07332 / 309115"
    const vorstandTelLink = `tel:${vorstandTel}`

    return (
        <div className="impressum_flex_div">
            <div className="impressum">
                <Typography variant="h2">
                    Impressum
                </Typography>
                <Typography>
                    Angaben gemäß §5 TGM
                </Typography>
                <Typography variant="h4">
                    Anschrift
                </Typography>
                <Typography>
                    Musikverein "Harmonie" Wiẞgoldingen 1930 e. V.
                </Typography>
                <Typography>
                    Friedhofstrasse 32
                </Typography>
                <Typography>
                    73111 Lauterstein-Nenningen
                </Typography>
                <Typography>
                    <strong>Vertreten durch:</strong>
                </Typography>
                <Typography>
                    Michael Wiget, 1. Vorsitzender
                </Typography>
                <Typography>
                    Friedhofstraße 32, 73111 Lauterstein-Nenningen
                </Typography>
                <Typography>
                    <strong>Telefon: </strong>
                    <Link href={vorstandTelLink} color="inherit">
                        {vorstandTel}
                    </Link>
                </Typography>
                <Typography>
                    <strong>Mobil: </strong>
                    <Link href={vorstandMobileLink} color="inherit">
                        {vorstandMobile}
                    </Link>
                </Typography>
                <Typography>
                    <strong>E-Mail: </strong>
                    <Link href={vorstandEmailLink} color="inherit">
                        {vorstandEmail}
                    </Link>
                </Typography>

                <Typography variant="h4">
                    Registereintrag
                </Typography>
                <Typography>
                    <strong>Registergericht:</strong> Amtsgericht Ulm
                </Typography>
                <Typography>
                    <strong>Registernummer:</strong> VR 700270
                </Typography>
                <Typography>
                    <strong>Datum der Eintragung:</strong> 28.04.1970
                </Typography>

                <Typography variant="h4">
                    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
                </Typography>
                <Typography>
                    Michael Wiget, 1. Vorsitzender
                </Typography>
                <Typography>
                    Friedhofstrasse 32
                </Typography>
                <Typography>
                    73111 Lauterstein-Nenningen
                </Typography>

                <Typography variant="h4">
                    Streitschlichtung
                </Typography>
                <Typography>
                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.
                </Typography>

                <Typography variant="h4">
                    Haftung für Inhalte
                </Typography>
                <Typography>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                    nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                    Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                    hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                    konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                    werden wir diese Inhalte umgehend entfernen.
                </Typography>

                <Typography variant="h4">
                    Haftung für Links
                </Typography>
                <Typography>
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
                    haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                    Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                    verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                    Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                    erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                    werden wir derartige Links umgehend entfernen.
                </Typography>

                <Typography variant="h4">
                    Urheberrecht
                </Typography>
                <Typography>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                    Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                    jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
                    nicht
                    kommerziellen Gebrauch gestattet.
                </Typography>
                <Typography>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                    Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                    trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                    umgehend entfernen.
                </Typography>
            </div>
        </div>
    );
}

export default Impressum;