import {createTheme} from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: '#851a34',
            dark: '#002884',
            contrastText: '#000',
        },
        secondary: {
            light: '#ff7961',
            main: '#252525',
            dark: '#ba000d',
            contrastText: '#000',
        },
        background: {
            main: "#fff",
        },
        onPrimary: {
            main: "#fff",
        },
        onSecondary: {
            main: "#fff",
        },
        onBackground: {
            main: "#000",
        },
        hover: {
            main: "#f4f4f4"
        }
    },
    typography: {
        h2: {
            fontSize: ["2rem", "!important"],
            '@media (min-width:600px)': {
                fontSize: '3.75rem',
            },
        },
        h4: {
            fontSize: ["1.75rem", "!important"],
            '@media (min-width:600px)': {
                fontSize: '2.125rem',
            },
        },
    }
});
