import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import MainPage from "./pages/MainPage";
import Jugend from "./pages/Jugend";
import {ThemeProvider} from "@mui/material";
import {theme} from "./MUITheme"
import Galerie from "./pages/Galerie";
import Impressum from "./pages/Impressum";
import Kontakt from "./pages/Kontakt";
import Datenschutz from "./pages/Datenschutz";
import Musikverein from "./pages/Musikverein";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "/jugend",
                element: <Jugend/>,
                state: {"scrollToComponent": null}
            },
            {
                path: "/musikverein",
                element: <Musikverein/>,
            },
            {
                path: "/galerie",
                element: <Galerie/>
            },
            {
                path: "/kontakt",
                element: <Kontakt/>
            },
            {
                path: "/datenschutz",
                element: <Datenschutz/>
            },
            {
                path: "/impressum",
                element: <Impressum/>
            },
            {
                path: "/",
                element: <MainPage/>
            },
            {
                path: "*",
                element: <MainPage/>
            }
        ]
    },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <RouterProvider router={router}/>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
