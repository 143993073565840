import './Jugend.css'

import {Grid, Link, Typography} from "@mui/material";
import juka_0 from "../images/juka_bilder/0_gruppenbild_vor_see.jpg";
import juka_1 from "../images/juka_bilder/1_bild_juka_probe.jpg";
import juka_2 from "../images/juka_bilder/2_auftritt_pfingsten.jpg";
import juka_3 from "../images/juka_bilder/3_freizeitaktion_wandern.jpg";
import juka_4 from "../images/juka_bilder/4_flagge_juka.jpg";
import {useEffect} from "react";
import {Element, scroller} from 'react-scroll';
import {useLocation} from "react-router-dom";

function Jugend() {
    const location = useLocation();

    const jugendEmail = 'alexhoran@arcor.de';
    const jugendEmailLink = `mailto:${jugendEmail}`;
    const jugendNumber = "0151 / 68193110"
    const jugendNumberLink = `tel:${jugendNumber}`

    useEffect(() => {
        if (location.state) {
            let {scrollToComponent} = location.state;
            console.log("scrollToElement: " + scrollToComponent)
            if (scrollToComponent) {
                scroller.scrollTo(scrollToComponent);
            }
        }
    });

    return (
        <div>
            <img
                className="juka_image_gesamt"
                src={juka_0}
                alt="Jugendkapelle Gruppenbild"
            />
            <div className="juka_outer">
                <Typography variant="h2" color="primary">
                    <strong>Jugendkapelle</strong>
                </Typography>
                <div className="juka_inner">
                    <Grid container columnSpacing={2} className="juka_grid">
                        <Grid item xs={12} md={6} order={{xs: 1, md: 1}} className="grid_text">
                            <Typography>
                                Unsere Jugendkapelle besteht aus ca. 20 jungen Musikern im Alter von 12 bis 22 Jahren.
                                Gespielt wird abwechslungsreiche Literatur.
                                Von Polkas über Rock bis hin zu Filmmusik ist alles dabei.
                                Geprobt wird jeden Donnerstag von 18:45 Uhr bis 19:45 Uhr.
                                Wir legen großen Wert auf Kameradschaft und außermusikalische Freizeitaktivitäten.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} order={{xs: 5, md: 2}}>
                            <img
                                className="grid_image"
                                src={juka_1}
                                alt="Jugendkapelle Probe"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} order={{xs: 2, md: 3}}>
                            <img
                                className="grid_image"
                                src={juka_2}
                                alt="Jugendkapelle Auftritte"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} order={{xs: 6, md: 4}} className="grid_text">
                            <Typography>
                                Jedes Jahr spielt die Jugendkapelle bei verschiedenen Auftritten, dazu gehören Konzerte
                                und
                                Sommerfeste,
                                aber auch mal in Freizeitparks.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} order={{xs: 3, md: 5}} className="grid_text">
                            <Typography>
                            Neben dem gemeinsamen Musizieren bieten wir ein attraktives Freizeitprogramm an.
                            In diesem Rahmen findet jedes Jahr am Anfang der Sommerferien eine sechstägige
                            Jugendfreizeit
                            statt, mit
                            Proben für das Herbstkonzert, aber auch mit reichlich abwechslungsreichen
                            Freizeitaktivitäten.
                            Zusätzlich findet jeden Monat eine gemeinsame Unternehmung statt, wie Kegeln,
                            Schlittenfahren,
                            Nachtwanderung, Hallenbadbesuch, Spieleabend und vieles mehr.
                            Diese soll das Gemeinschaftsgefühl und das tatkräftige Miteinander unseres Vereins
                            fördern.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} order={{xs: 7, md: 6}}>
                            <img
                                className="grid_image"
                                src={juka_3}
                                alt="Jugendkapelle Freizeit"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} order={{xs: 4, md: 7}}>
                            <img
                                className="grid_image"
                                src={juka_4}
                                alt="Jugendkapelle Flagge"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} order={{xs: 8, md: 8}} className="grid_text">
                        <Typography>
                            Die Jugendkapelle wird vom Jugendteam geleitet.
                            Dieses besteht aus dem Jugendleiter Alex Horan, dem Jugenddirigenten Florian Häfele, den
                            Jugendbetreuerinnen
                            Stefanie König und Carina Merz und zwei Jugendvertreter*innen aus den Reihen der
                            Jugendkapelle.
                            Die beiden Jugendvertreter*innen werden direkt von den Mitgliedern der Jugendkapelle
                            gewählt
                            und
                            repräsentieren deren Meinung im Jugendteam.
                        </Typography>
                        </Grid>
                    </Grid>
                    <Element name="ausbildung">
                        <Typography variant="h2" color="primary">
                            <strong>Ausbildung</strong>
                        </Typography>
                    </Element>
                    <div className="ausbildung">
                        <Typography variant="h6">
                            Welche Instrumente können bei uns erlernt werden?
                        </Typography>
                        <Typography>
                            <strong>Blechblasinstrumente:</strong> Trompete, Flügelhorn, Waldhorn, Tenorhorn, Bariton,
                            Posaune
                            oder Tuba
                        </Typography>
                        <Typography>
                            <strong>Holzblasinstrumente:</strong> Querflöte, Klarinette, Saxophon, Fagott, Oboe und
                            Bassklarinette
                        </Typography>
                        <Typography>
                            <strong>Schlagzeug:</strong> Dazu gehört das kombinierte Set mit kleiner und großer Trommel,
                            Becken,
                            Hi-Hat, Tom Toms, außerdem die Perkussionsinstrumente, Stabspiele sowie die Pauken
                        </Typography>
                        <Typography variant="h6">
                            Bezüglich der Instrumentenbeschaffung gibt es drei Varianten:
                        </Typography>
                        <ul>
                            <li>
                                Die Eltern erwerben nach Absprache mit dem Instrumentallehrer ein Instrument
                            </li>
                            <li>
                                Der Verein bietet einen zinslosen Mietkauf an
                            </li>
                            <li>
                                Bei sehr teuren Instrumenten oder im speziellen Einzelfall stellt der Verein ein
                                Instrument zur Verfügung
                            </li>
                        </ul>
                        <Typography variant="h6">
                            Wie verläuft die Ausbildung?
                        </Typography>
                        <Typography>
                            Die Ausbildung beginnt meist im Alter ab ca. 8 Jahren, kann jedoch auch später
                            begonnen werden. Die Ausbildung dauert mindestens vier Jahre. Einmal wöchentlich
                            findet Einzelunterricht statt. Während dieser Zeit werden praktische sowie auch
                            theoretische Grundkenntnisse vom Ausbilder vermittelt. In den Schulferien findet kein
                            Unterricht statt. Zudem werden dem Schüler, je nach Leistungsstand, Kurse auf
                            Kreisebene angeboten, deren Kosten der Musikverein trägt.
                        </Typography>
                        <Typography>
                            Anmeldungen zum Instrumentalunterricht sind jederzeit beim Jugendleiter möglich.
                        </Typography>
                        <Typography variant="h6">
                            Wie geht es nach der Ausbildung weiter?
                        </Typography>
                        <Typography>
                            Mit entsprechendem Ausbildungsstand werden die Jugendlichen in die Jugendkapelle
                            integriert.
                            Nach Erreichen der notwendigen musikalischen Fähigkeiten werden die Jugendlichen danach in
                            das aktive Blasorchester aufgenommen. Jung und Alt musizieren hier gemeinsam.
                        </Typography>
                    </div>
                    <Element name="blockfloete">
                        <Typography variant="h2" color="primary">
                            <strong>Blockflötenunterricht</strong>
                        </Typography>
                    </Element>
                    <div className="blockfloetenunterricht">
                        <Typography>
                            Der Musikverein Harmonie Wiẞgoldingen bietet Blockflötenunterricht für Kinder ab der 1.
                            Klasse
                            an.
                            Natürlich ist auch ein Quereinstieg möglich.
                        </Typography>
                        <Typography>
                            Der Unterricht findet einmal pro Woche für max. 45 min. in Kleingruppen von max. 3 Kindern
                            statt. In
                            den Schulferien ist kein Unterricht.
                            Die Blockflötenschüler*innen können bei Auftritten (z.B. Vorspielnachmittag,
                            Jahresabschluss)
                            das
                            bereits Erlernte präsentieren.
                        </Typography>
                        <Typography>
                            Die Kinder lernen neben dem Spielen auf der Blockflöte die Grundzüge der Musik: Noten lesen,
                            Rhythmik und vor allem Spaß am gemeinsamen Musizieren. Der Unterricht wird von
                            Vereinsmitgliedern geleitet.
                        </Typography>
                        <Typography>
                            <strong>Alter:</strong> Ab der 1. Klasse
                        </Typography>
                        <Typography>
                            <strong>Ort:</strong> Musikerräumle bei der Grundschule Wißgoldingen
                        </Typography>
                        <Typography>
                            <strong>Gruppengröße:</strong> maximal 3 Schüler pro Gruppe
                        </Typography>
                        <Typography>
                            <strong>Kosten:</strong> 20€ pro Monat sowie einmalige Anschaffung Flöte und Noten
                        </Typography>
                        <Typography>
                            <strong>Instrument:</strong> Beschaffung der Blockflöte und der Noten über den Musikverein
                        </Typography>
                        <Typography>
                            Bei Interesse oder Fragen können Sie sich gerne bei Alexander Horan per E-Mail oder Handy
                            melden.
                        </Typography>
                        <Typography>
                            <strong>Handy/ Whatsapp: </strong>
                            <Link href={jugendNumberLink} color="inherit">
                                {jugendNumber}
                            </Link>
                        </Typography>
                        <Typography>
                            <strong>E-Mail: </strong>
                            <Link href={jugendEmailLink} color="inherit">
                                {jugendEmail}
                            </Link>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Jugend;